.login-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;

  .login-left-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('./../../images/signin-graphics.png');
    flex-grow: 1;
    background-repeat: no-repeat;
    background-size: cover;
    &-bottom {
      height: 70px;
      width: 351px;
      color: #FFFFFF;
      font-size: 26px;
      font-weight: 500;
      line-height: 35px;
      margin-left: 68px;
      margin-bottom: 50px;
    }
  }

  .login-right-part {
    flex-grow: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .login-logo {
    height: 28px;
    object-fit: cover;
  }
}

.request-invitation-wrapper-demo {
  color: #0075ac;
  font-size: 14px;
  letter-spacing: .35px;
  line-height: 30px;
}

.request-email {
  width: 80%;
  height: 25px;
  padding: .6rem 1rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 2;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  margin-top: 8px;
}

.request-invitation-wrapper {
  .form-label {
    font-size: 12px;
  }
  .request-invt-button  {
    &-inactive{
      color: #ffffff;
      width: auto;
      height: 45px;
      border-radius: 4px;
      border: 0 #AFD6EE;
      background-color: #AFD6EE;
      box-shadow: 0 5px 9px 0 rgba(55,152,212,0.17);
      padding: 0.4rem 1rem;
    }
    &-active {
      color: #ffffff;
      width: auto;
      height: 45px;
      border: 2px solid #3798D4;
      border-radius: 4px;
      background-color: #3798D4;
      box-shadow: 0 5px 9px 0 rgba(55,152,212,0.17);
      padding: 0.4rem 1rem;
    }
  }
  .request-invt-button-inactive:disabled {
    opacity: 1;
  }
}

.form-group {
  margin-bottom: 1rem;
}

//body {
//  font-family: -apple-system, BlinkMacSystemFont, Nunito Sans, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
//  font-size: .875rem;
//  font-weight: 400;
//  line-height: 2;
//  color: #212529;
//  text-align: left;
//  background-color: #fff;
//}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.request-submit-status-success {
  padding: 15px 0;
  border: 1px solid #cff8c5;
  border-radius: 4px;
  background-color: #cff8c5;
  color: #0a6c2a;
  font-size: 14px;
  letter-spacing: .23px;
  line-height: 16px;
  display: flex;
  justify-self: center;
  align-self: center;
}

.green-tick-icon {
  margin: 0 10px 0 20px;
}

.request-submit-status-text {
  margin-top: 7px;
  color: #56585d;
  font-size: 12px;
  letter-spacing: .2px;
  line-height: 18px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #login-left-part {
    display: none;
  }
  .login-logo-container {
    padding: 0px;
  }

  .login-right-part-logo-mobile{
    width: 100%;
    padding-left: 15px;
    margin: 15px 0px 25px 0px;
  }

  .login-right-part {
    justify-content: initial;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #login-left-part {
    display: none;
  }
  .login-logo-container {
    padding: 0px;
  }

  .login-right-part-logo-mobile {
    width: 100%;
    padding-left: 15px;
    margin: 15px 0px 25px 0px;
  }

  .login-right-part {
    justify-content: initial;
  }

  .login-container {
    min-width: none;
    max-width: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #login-left-part {
    display: flex;
  }
  .login-logo-container {
    padding: 72px 64px;
  }
  .login-right-part-logo-mobile {
    display: none;
  }

  .login-right-part {
    justify-content: center;
  }

  .login-container {
    min-width: 380px !important;
    max-width: 480px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  #login-left-part {
    display: flex;
  }
  .login-logo-container {
    padding: 72px 64px;
  }
  .login-right-part-logo-mobile {
    display: none;
  }
  .login-right-part {
    justify-content: center;
  }
  .login-container {
    min-width: 380px !important;
    max-width: 480px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  #login-left-part {
    display: flex;
  }
  .login-logo-container {
    padding: 72px 64px;
  }
  .login-right-part-logo-mobile {
    display: none;
  }

  .login-right-part {
    justify-content: center;
  }
  .login-container {
    min-width: 380px !important;
    max-width: 480px !important;
  }
}
